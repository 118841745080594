
import { defineComponent, ref } from 'vue';
import { FetchStates } from '@/helpers/fetch-states';
import { getGlobalConfig, setGlobalConfig } from '@/api/accidentplan-api';
import { OpenWeatherMapAPIKeySetting } from '@/api/dto/global-config.dto';
import { alertError } from '@/helpers/alert-error';
import { ElMessage } from 'element-plus';

const SETTING_KEY = 'OPEN_WEATHER_MAP_API_KEY_SETTING';

export default defineComponent({
  setup() {
    const apiKey = ref('');
    const fetchState = ref(FetchStates.UNFETCHED);

    getGlobalConfig<OpenWeatherMapAPIKeySetting>(SETTING_KEY).then(setting => {
      apiKey.value = setting.value.key;
      fetchState.value = FetchStates.FETCHED;
    });

    const setAPIKey = async () => {
      fetchState.value = FetchStates.FETCHING;

      try {
        await setGlobalConfig<OpenWeatherMapAPIKeySetting>(SETTING_KEY, {
          key: apiKey.value,
        });
      } catch (err) {
        alertError(err, 'There was an error setting the API key.');
        return;
      } finally {
        fetchState.value = FetchStates.FETCHED;
      }

      ElMessage.success('Successfully updated the API key.');
    };

    return { apiKey, fetchState, setAPIKey, FetchStates };
  },
});
